import useFetch from "../api/useFetch";
import Loading from "./Loading";
import { useState, useEffect } from "react";
import Table from "./Table";

const Teams = () => {
  const { result: teams, isLoading: teamsLoading, error: teamsError } = useFetch('/teams', []);
  const [homeAway, setHomeAway] = useState(null);
  const [selectedLeague, setSelectedLeague] = useState(null);
  const [teamsTableContent, setTeamsTableContent] = useState(null);
  const [delta, setDelta] = useState("-1");

  const teamsTableHeader = [
    { content: "Team", size: "md", isLink: true, link: 'team' },
    { content: "League", size: "md", isLink: true, link: 'league' },
    { content: "Nb", size: "sm", isLink: false },
    { content: "Win", size: "sm", isLink: false },
    { content: "! Win", size: "sm", isLink: false },
    { content: "Lose", size: "sm", isLink: false },
    { content: "! Lose", size: "sm", isLink: false },
    { content: "Draw", size: "sm", isLink: false },
    { content: "G F", size: "sm", isLink: false },
    { content: "G A", size: "sm", isLink: false },
    { content: "G F+A", size: "sm", isLink: false },
    { content: "+ 2.5", size: "sm", isLink: false },
    { content: "CS F", size: "sm", isLink: false },
    { content: "CS A", size: "sm", isLink: false },
    { content: "BTTS", size: "sm", isLink: false },
    { content: "UNXF", size: "sm", isLink: false, sort: 'normal' }
  ];

  useEffect(() => {
    if (teams !== null) {
        // Start with all teams
        let filteredTeams = teams.items;

        // Apply "Today Games" filter if active
        if (delta === "-1") {
          filteredTeams = teams.items;
        }
        else {
          if (homeAway === "HOME") {
            filteredTeams = filteredTeams.filter(team => team.next_game_home === parseInt(delta));
          } else if (homeAway === "AWAY") {
            filteredTeams = filteredTeams.filter(team => team.next_game_away === parseInt(delta));
          }
          else {
            filteredTeams = filteredTeams.filter(team => team.next_game === parseInt(delta));
          }
        }
        // Apply league filter if a league is selected
        if (selectedLeague !== null) {
            filteredTeams = filteredTeams.filter(team => team.league && team.league.id === selectedLeague.id);
        }

        setTeamsTableContent(
            filteredTeams.filter(team => team.league !== null).map((team) => [
                [team.name, team.id],
                [team.league.name, team.league.api_id],
                homeAway === "AWAY" ? parseInt(team.away_count) : homeAway === "HOME" ? parseInt(team.home_count) : parseInt(team.count),
                homeAway === "AWAY" ? (1 / team.away_win).toFixed(2) : homeAway === "HOME" ? (1 / team.home_win).toFixed(2) : (1 / team.win).toFixed(2),
                homeAway === "AWAY" ? (1 / team.away_not_win).toFixed(2) : homeAway === "HOME" ? (1 / team.home_not_win).toFixed(2) : (1 / team.not_win).toFixed(2),
                homeAway === "AWAY" ? (1 / team.away_lose).toFixed(2) : homeAway === "HOME" ? (1 / team.home_lose).toFixed(2) : (1 / team.lose).toFixed(2),
                homeAway === "AWAY" ? (1 / team.away_not_lose).toFixed(2) : homeAway === "HOME" ? (1 / team.home_not_lose).toFixed(2) : (1 / team.not_lose).toFixed(2),
                homeAway === "AWAY" ? (1 / (1 - team.away_win - team.away_lose)).toFixed(2) : homeAway === "HOME" ? (1 / (1 - team.home_win - team.home_lose)).toFixed(2) : (1 / (1 - team.win - team.lose)).toFixed(2),
                homeAway === "AWAY" ? parseFloat(team.away_goals_for).toFixed(1) : homeAway === "HOME" ? parseFloat(team.home_goals_for).toFixed(1) : parseFloat(team.goals_for).toFixed(1),
                homeAway === "AWAY" ? parseFloat(team.away_goals_against).toFixed(1) : homeAway === "HOME" ? parseFloat(team.home_goals_against).toFixed(1) : parseFloat(team.goals_against).toFixed(1),
                homeAway === "AWAY" ? parseFloat(team.away_goals_total).toFixed(1) : homeAway === "HOME" ? parseFloat(team.home_goals_total).toFixed(1) : parseFloat(team.goals_total).toFixed(1),
                homeAway === "AWAY" ? (1 / team.away_over_2_5_goals).toFixed(2) : homeAway === "HOME" ? (1 / team.home_over_2_5_goals).toFixed(2) : (1 / team.over_2_5_goals).toFixed(2),
                homeAway === "AWAY" ? (1 / team.away_cs_for).toFixed(2) : homeAway === "HOME" ? (1 / team.home_cs_for).toFixed(2) : (1 / team.cs_for).toFixed(2),
                homeAway === "AWAY" ? (1 / team.away_cs_against).toFixed(2) : homeAway === "HOME" ? (1 / team.home_cs_against).toFixed(2) : (1 / team.cs_against).toFixed(2),
                homeAway === "AWAY" ? (1 / team.away_btts).toFixed(2) : homeAway === "HOME" ? (1 / team.home_btts).toFixed(2) : (1 / team.btts).toFixed(2),
                homeAway === "AWAY" ? team.away_unexpected_factor : homeAway === "HOME" ? team.home_unexpected_factor : team.unexpected_factor,
            ])
        );
    }
}, [teams, selectedLeague, homeAway, delta]);


  return (
    <div className="content">
      <div className="container">
        <div className="league-choice">
          {!teamsLoading && teams.leagues.map((league) => (
            <img
              key={league.id}
              src={league.logo_url}
              alt={league.name}
              onClick={() => { selectedLeague === league ? setSelectedLeague(null) : setSelectedLeague(league); }}
              className={selectedLeague === league ? 'active' : ''}
            />
          ))}
        </div>
          {!teamsLoading && !teamsError && (
            <>
            <div className="buttons">
              <div
                className={`button ${homeAway === "HOME" ? 'active' : ''}`}
                onClick={() => { homeAway === "HOME" ? setHomeAway(null) : setHomeAway("HOME"); }}
          >
            HOME ONLY
          </div>
          <select style={{ textAlign: 'center', fontFamily: 'Montserrat' }} onChange={(e) => setDelta(e.target.value)} value={delta}>
              <option value="-1">All Teams</option>
              <option value="0">Teams with {homeAway === "HOME" ? "home " : homeAway === "AWAY" ? "away " : ""}game today</option>
              <option value="1">Teams with {homeAway === "HOME" ? "home " : homeAway === "AWAY" ? "away " : ""}game tomorrow</option>
              <option value="2">Teams with {homeAway === "HOME" ? "home " : homeAway === "AWAY" ? "away " : ""}game in 2 days</option>
              <option value="3">Teams with {homeAway === "HOME" ? "home " : homeAway === "AWAY" ? "away " : ""}game in 3 days</option>
              <option value="4">Teams with {homeAway === "HOME" ? "home " : homeAway === "AWAY" ? "away " : ""}game in 4 days</option>
              <option value="5">Teams with {homeAway === "HOME" ? "home " : homeAway === "AWAY" ? "away " : ""}game in 5 days</option>
              <option value="6">Teams with {homeAway === "HOME" ? "home " : homeAway === "AWAY" ? "away " : ""}game in 6 days</option>
              <option value="7">Teams with {homeAway === "HOME" ? "home " : homeAway === "AWAY" ? "away " : ""}game in 7 days</option>
            </select>
          <div
            className={`button ${homeAway === "AWAY" ? 'active' : ''}`}
            onClick={() => { homeAway === "AWAY" ? setHomeAway(null) : setHomeAway("AWAY"); }}
          >
            AWAY ONLY
          </div>
        </div>
        </>
        )}

        {teams && !teamsLoading && !teamsError && (
          <Table tableHeader={teamsTableHeader} tableContent={teamsTableContent} />
        )}
        {teamsLoading && <Loading />}
        {teamsError && <>{teamsError} - Please contact Florent</>}
      </div>
    </div>
  );
};

export default Teams;
