import useFetch from '../api/useFetch';
import { useState, useEffect } from 'react';
import Loading from './Loading';
// import { Link } from 'react-router-dom';
import Table from './Table';
import '../css/main.css';


const Leagues = () => {
    // const [continent, setContinent] = useState("Champion_Europe")
    const { result: leagues, isLoading: leaguesLoading, error: leaguesError } = useFetch(`/current_leagues`,[]);
    const [leaguesTableContent, setLeaguesTableContent] = useState(null);

    const leaguesTableHeader = [
      { content: "League", size: "md", isLink: true, link: 'league' },
      { content: "Nb", size: "sm", isLink: false },
      { content: "1", size: "sm", isLink: false },
      { content: "X", size: "sm", isLink: false },
      { content: "2", size: "sm", isLink: false },
      { content: "0-0", size: "sm", isLink: false },
      { content: "U 1.5", size: "sm", isLink: false },
      { content: "U 2.5", size: "sm", isLink: false },
      { content: "U 3.5", size: "sm", isLink: false },
      { content: "U 4.5", size: "sm", isLink: false },
      { content: "U 5.5", size: "sm", isLink: false },
      { content: "Goals", size: "sm", isLink: false },
      { content: "BTTS", size: "sm", isLink: false },
    ];
    
    useEffect(() => {
      if (leagues !== null) {
     setLeaguesTableContent(
              leagues.filter(league => parseInt(league.count) >  10).map((league) => [
                  [league.name, league.api_id],
                  league.count.split(".")[0],
                  (1 / league.home_win).toFixed(2),
                  (1 / league.draw).toFixed(2),
                  (1 / league.away_win).toFixed(2),
                  (1 / league.under_0_5_goals).toFixed(2),
                  (1 / league.under_1_5_goals).toFixed(2),
                  (1 / league.under_2_5_goals).toFixed(2),
                  (1 / league.under_3_5_goals).toFixed(2),
                  (1 / league.under_4_5_goals).toFixed(2),
                  (1 / league.under_5_5_goals).toFixed(2),
                  parseFloat(league.goals).toFixed(2),
                  (1 / league.btts).toFixed(2),
              ])
          );
      }
  }, [leagues]);
  

  return (
    <>
    <div class="container">
    {/* <div class="tabs-container">
      <div onClick={() => setContinent("Champion_Europe")} class={continent === "Champion_Europe" ? 'tab active' : 'tab'}>Champion Europe</div>
      <div onClick={() => setContinent("Challenger_Europe")} class={continent === "Challenger_Europe" ? 'tab active' : 'tab'}>Challenger Europe</div>
      <div onClick={() => setContinent("Second_Division")} class={continent === "Second_Division" ? 'tab active' : 'tab'}>Second Division</div>
      <div onClick={() => setContinent("Asia")} class={continent === "Asia" ? 'tab active' : 'tab'}>Asia</div>
      <div onClick={() => setContinent("America")} class={continent === "America" ? 'tab active' : 'tab'}>America</div>
      <div onClick={() => setContinent("Africa")} class={continent === "Africa" ? 'tab active' : 'tab'}>Africa</div>
      <div onClick={() => setContinent("Cup")} class={continent === "Cup" ? 'tab active' : 'tab'}>Cup</div>
    </div>
    <div className="content">
  {leaguesLoading ? (
    <Loading />
  ) : !leaguesError && leagues.length > 0 ? (
    leagues.map((league) => (
        <Link to={`/league/${league.api_id}`}>
      <div className="header" key={league.api_id}>
        <img className="header-logo" src={league.logo_url} alt={league.name} />
      </div>
      </Link>
    ))
  ) : (
    <div>No leagues available at this time.</div>
  )}
</div> */}
{leaguesLoading && <Loading />}
{!leaguesLoading && !leaguesError && leagues.length > 0 && <Table tableContent={leaguesTableContent} tableHeader={leaguesTableHeader} />}
{leaguesError && <>{leaguesError} - Please contact Florent</>}
  </div>

</>
  )
}

export default Leagues;